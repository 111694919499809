import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Skills Practice:`}</p>
    <p>{`Rope Climbs (with legs, legless, l hold, etc)`}</p>
    <p>{`HS Holds`}</p>
    <p>{`then,`}</p>
    <p>{`Buy In: 50ft HS Walk (Scaled to 150ft Bear Crawl)`}</p>
    <p>{`5 Rounds of:`}</p>
    <p>{`1-Rope Climb`}</p>
    <p>{`15-KBS (53/35)`}</p>
    <p>{`15-Walking Lunges/Leg`}</p>
    <p>{`15-V Ups`}</p>
    <p>{`15-Pushups`}</p>
    <p>{`Cash Out: 50ft HS Walk (Scaled to 150ft Bear Crawl)`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This Saturday, January 13th is Cardinal Fitness Day at the Yum!.
 The Cards play Virginia Tech at 4:00pm.  With the following link you
can get discounted tickets as a CrossFit the Ville member!  After the
game there will be a CrossFit wod on the court along with several other
workout sessions.  Lets get a big group together and represent The Ville
at the Yum center!  Family is invited as well.`}</em></strong></p>
    <p><strong parentName="p"><a parentName="strong" {...{
          "href": "https://fevo.me/fitday"
        }}>{`https://fevo.me/fitday`}</a></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      